import React from "react";
import { storyblokEditable } from "@storyblok/react";
import type {
  HeroDeepdiveStoryblok,
  MarkedTextStoryblok,
  AliniaStoryblok,
  ButtonStoryblok,
  DownloadItemStoryblok,
} from "../../../component-types-sb";
import "./herodeep.scss";
import MarkedText from "../../elements/MarketText";
import Alinia from "../../elements/Alinia";
import Button from "../../elements/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const HeroDeep = (blok: HeroDeepdiveStoryblok) => {
  blok = blok.blok;

  return (
    <section className="hero-deep" {...storyblokEditable(blok)}>
      <div className="text-container">
        {blok.titleSupport && (
          <div className="sub-tag-container">
            <p className="sub-tag">{blok.titleSupport}</p>
          </div>
        )}
        {blok.title &&
          blok.title.map((blok: MarkedTextStoryblok) => (
            <MarkedText
              startText={blok.beforeText}
              circleText={blok.circleText}
              middelText={blok.middelText}
              underlinedText={blok.underline}
              endText={blok.afterText}
              handwritten={blok.handwritten}
              isSmall={false}
              center={false}
              key={blok._uid}
              className="title"
            />
          ))}
        {blok.description &&
          blok.description.map((blok: AliniaStoryblok) => (
            <Alinia
              key={blok._uid}
              className="alinia-space"
              text={blok.alinia}
            />
          ))}
        {blok.button &&
          blok.button.map((blok: ButtonStoryblok) => (
            <Button
              link={blok.Link.url}
              target={blok.Link.target}
              emailType={blok.Link.linktype}
              key={blok._uid}
              label={blok.text}
              className="buy-hero-deep"
              icon="arrow"
            />
          ))}
        {blok.downloads &&
          blok.downloads.map((blok: DownloadItemStoryblok) => (
            <div key={blok._uid}>
              <p className="download-text">{blok.text}</p>
              <div className="download-container">
                {blok.items &&
                  blok.items.map((blok: ButtonStoryblok) => (
                    <Button
                      link={blok.Link.url}
                      target={blok.Link.target}
                      key={blok._uid}
                      label={blok.text}
                      className="download-hero"
                      icon="download"
                    />
                  ))}
              </div>
            </div>
          ))}
      </div>
      {blok.image && (
        <div className="image-container">
          <LazyLoadImage
            className={`${blok.fullscreen ? "fullscreen-image" : "image"}`}
            src={blok.image.filename}
            alt={blok.image.alt}
            effect="blur"
          />
        </div>
      )}
    </section>
  );
};
export default HeroDeep;
