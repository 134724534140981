import { storyblokEditable } from "@storyblok/react";
import "./bio.scss";
import MarkedText from "../../elements/MarketText";
import Alinia from "../../elements/Alinia";

import type {
  TextBlokStoryblok,
  SectionTagItemStoryblok,
  MarkedTextStoryblok,
  AliniaStoryblok,
} from "../../../component-types-sb";

const TextBlok = (blok: TextBlokStoryblok) => {
  blok = blok.blok;

  return (
    <section className="bio" {...storyblokEditable(blok)}>
      <div className="text-container">
        {blok.sectionTags &&
          blok.sectionTags.map((blok: SectionTagItemStoryblok) => (
            <p
              key={blok._uid}
              className={`tag ${blok.color === "pink" ? "pink" : "black"}`}
            >
              {blok.text}
            </p>
          ))}

        {blok.titleSupport && (
          <div className="sub-tag-container">
            <p className="sub-tag">{blok.titleSupport}</p>
          </div>
        )}

        {blok.title &&
          blok.title.map((blok: MarkedTextStoryblok) => (
            <MarkedText
              startText={blok.beforeText}
              circleText={blok.circleText}
              middelText={blok.middelText}
              underlinedText={blok.underline}
              endText={blok.afterText}
              handwritten={blok.handwritten}
              isSmall={false}
              center
              className=""
              key={blok._uid}
            />
          ))}
        <div className="bio-container">
          {blok.text &&
            blok.text.map((blok: AliniaStoryblok) => (
              <Alinia className="text-bio" key={blok._uid} text={blok.alinia} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default TextBlok;
