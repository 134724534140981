import "./alinia.scss";

type AliniaProps = {
  text: string;
  className?: string;
};

const Alinia = ({ text, className }: AliniaProps) => {
  return <p className={`alinia ${className}`}>{text}</p>;
};
export default Alinia;
