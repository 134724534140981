type LogoProps = {
  className?: string;
};
const Logo = ({ className }: LogoProps) => {
  return (
    <svg
      width="75"
      height="64"
      viewBox="0 0 75 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.9808 15.3257C22.9808 17.8268 21.1908 20.1073 18.1747 20.1073C15.1831 20.1073 13.1234 18.2437 13.1234 16.2575V13.6337H16.8015V15.4728C16.8015 16.2084 17.2429 16.7479 18.003 16.7479C18.7387 16.7479 19.082 16.331 19.082 15.4728C19.082 13.8544 18.0276 12.5303 16.3111 10.8383C14.5456 9.0728 13.344 7.01303 13.344 4.78161C13.344 2.10881 15.1096 0 18.2728 0C20.872 0 22.8827 1.8636 22.8827 3.84981V6.47356H19.1801V4.48736C19.1801 3.80077 18.7877 3.35939 18.2728 3.35939C17.7578 3.35939 17.2429 3.80077 17.2429 4.48736C17.2429 5.95862 17.6597 6.44904 19.6214 8.75402C21.1908 10.5931 22.9808 12.5303 22.9808 15.3257Z"
        fill="#252632"
      />
      <path
        d="M33.4122 19.8621H29.3417L29.0965 16.7969H27.331L26.9632 19.8621H23.4812L26.0804 0.245212H30.764L33.4122 19.8621ZM28.8758 13.3149L28.3854 6.8659L27.6498 13.3149H28.8758Z"
        fill="#252632"
      />
      <path
        d="M44.4659 19.8621H41.0329L37.7226 9.88199V19.8621H34.4368V0.245212H38.0168L41.1801 10.3969V0.245212H44.4659V19.8621Z"
        fill="#252632"
      />
      <path
        d="M56.0262 19.8621H52.5933L49.2829 9.88199V19.8621H45.9971V0.245212H49.5772L52.7404 10.3969V0.245212H56.0262V19.8621Z"
        fill="#252632"
      />
      <path
        d="M65.2816 19.8621H57.5575V0.245212H65.2816V3.48199H61.4808V7.94483H63.982V11.4268H61.4808V16.3801H65.2816V19.8621Z"
        fill="#252632"
      />
      <path
        d="M10.0536 22.3142L6.84138 41.931H3.21226L0 22.3142H3.89885L5.10038 33.5203L6.47356 22.3142H10.0536Z"
        fill="#252632"
      />
      <path
        d="M19.1874 41.931H15.1169L14.8716 38.8659H13.1061L12.7383 41.931H9.25632L11.8556 22.3142H16.5391L19.1874 41.931ZM14.651 35.3839L14.1605 28.9349L13.4249 35.3839H14.651Z"
        fill="#252632"
      />
      <path
        d="M30.241 41.931H26.808L23.4977 31.951V41.931H20.2119V22.3142H23.792L26.9552 32.4659V22.3142H30.241V41.931Z"
        fill="#252632"
      />
      <path
        d="M16.359 64H12.2885L12.0433 60.9349H10.2778L9.90998 64H6.42799L9.02723 44.3831H13.7108L16.359 64ZM11.8226 57.4529L11.3322 51.0038L10.5966 57.4529H11.8226Z"
        fill="#252632"
      />
      <path
        d="M26.9222 64H23.1215C22.8272 63.1418 22.6311 61.9893 22.6311 60.9594V56.8398C22.6311 56.423 22.3613 56.0797 21.8219 56.0797H21.3069V64H17.3835V44.3831H22.1897C24.0778 44.3831 26.3583 45.4621 26.3583 47.9632V52.3525C26.3583 53.2843 25.7207 53.9954 24.7153 54.5103C25.7207 55.0253 26.4318 55.7364 26.4318 56.6682V60.7142C26.4318 61.9648 26.7016 63.0437 26.9222 64ZM22.533 52.0582V48.6498C22.533 48.0858 22.2878 47.8651 21.8709 47.8651H21.3069V52.8429H21.8709C22.2878 52.8429 22.533 52.6222 22.533 52.0582Z"
        fill="#252632"
      />
      <path
        d="M37.8475 64H34.4146L31.1042 54.0199V64H27.8184V44.3831H31.3985L34.5617 54.5349V44.3831H37.8475V64Z"
        fill="#252632"
      />
      <path
        d="M49.0155 64H45.0676V56.0797H43.3021V64H39.3788V44.3831H43.3021V52.5977H45.0676V44.3831H49.0155V64Z"
        fill="#252632"
      />
      <path
        d="M58.2562 64H50.532V44.3831H58.2562V47.6199H54.4554V52.0828H56.9565V55.5648H54.4554V60.518H58.2562V64Z"
        fill="#252632"
      />
      <path
        d="M74.1464 64H70.223V51.5188L67.7709 64H65.1962L62.9893 51.4943V64H59.4583V44.3831H65.4414L66.8881 53.3333L68.433 44.3831H74.1464V64Z"
        fill="#252632"
      />
    </svg>
  );
};

export default Logo;
