import { useState } from "react";
import HamburgerSvg from "../../components/SVG/Hamburger";
import "./hamburgermenu.scss";
import Logo from "../../components/SVG/LogoSvg";
import HamOpen from "../../components/SVG/HamburgerOpen";
import { NavLink } from "react-router-dom";

const HamburgerMenu = () => {
  const [toggle, setToggle] = useState(false);
  function clickhandller() {
    return setToggle(!toggle);
  }
  return (
    <>
      <nav className={`hamburger-menu ${toggle ? "" : ""}`}>
        <a className="logo" href="/">
          <Logo className="logo" />
        </a>
        <div className="hamburger " onClick={() => clickhandller()}>
          <div className={`dot ${toggle ? "active" : ""}`}></div>
          {toggle ? <HamOpen /> : !toggle ? <HamburgerSvg /> : ""}
        </div>
      </nav>
      <div className={`listNav ${toggle ? "" : "none"}`}>
        <div className="container-sec">
          <ul className="footer-nav">
            <li>
              <NavLink to="trainingen" onClick={() => clickhandller()}>
                Trainingen
              </NavLink>
            </li>
            <li>
              <NavLink to="coaching" onClick={() => clickhandller()}>
                Coaching
              </NavLink>
            </li>
            <li>
              <NavLink to="boeken" onClick={() => clickhandller()}>
                Boeken
              </NavLink>
            </li>
            <li>
              <NavLink to="lezingen" onClick={() => clickhandller()}>
                Lezingen en shows
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="container-third">
          <div className="social-container">
            <a
              target="_blank"
              href="https://www.instagram.com/sannevanarnhem/"
              rel="noreferrer"
            >
              instagram
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/sannevanarnhem/"
              rel="noreferrer"
            >
              linkedin
            </a>
          </div>
          <div className="contact-container">
            <a target="_blank" href="tel:0031645810392" rel="noreferrer">
              0031645810392
            </a>
            <a
              target="_blank"
              href="mailto:sanne@sannevanarnhem.nl"
              rel="noreferrer"
            >
              sanne@sannevanarnhem.nl
            </a>
          </div>
        </div>
        <div className="container-fourth">
          <p className="rights">©2024 All Rights reserved</p>
        </div>
      </div>
    </>
  );
};
export default HamburgerMenu;
