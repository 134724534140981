const Circle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="142"
      height="99"
      viewBox="0 0 142 99"
      fill="none"
    >
      <path
        d="M123.19 13.553C88.0892 1.1945 15.0033 -11.7819 3.46342 35.1804C-10.9614 93.8833 85.0841 106.524 123.19 90.7937C146.073 81.3471 159.385 18.8363 68.5084 9.56737"
        stroke="#E90D7D"
        stroke-width="3"
      />
    </svg>
  );
};

export default Circle;
