type DLPRops = {
  className: string;
};

const DownloadIcon = ({ className }: DLPRops) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      className={className}
    >
      <path
        d="M15.3431 11.7071L21.7071 5.34315C22.0976 4.95262 22.0976 4.31946 21.7071 3.92893C21.3166 3.53841 20.6834 3.53841 20.2929 3.92893L15.636 8.58579V0H13.636V8.58579L8.97918 3.92893C8.58866 3.53841 7.95549 3.53841 7.56497 3.92893C7.17444 4.31946 7.17444 4.95262 7.56497 5.34315L13.9289 11.7071C14.3195 12.0976 14.9526 12.0976 15.3431 11.7071Z"
        fill="#252632"
      />
      <path
        d="M2 7C2 6.44772 1.55228 6 1 6C0.447715 6 0 6.44772 0 7V17C0 17.5523 0.447715 18 1 18H27C27.5523 18 28 17.5523 28 17V7C28 6.44772 27.5523 6 27 6C26.4477 6 26 6.44772 26 7V16H2V7Z"
        fill="#252632"
      />
    </svg>
  );
};
export default DownloadIcon;
