import { NavLink } from "react-router-dom";
import "./NavMain.scss";
import Logo from "../SVG/LogoSvg";
import useScreenSize from "../../screenSize";
import HamburgerMenu from "../../elements/HamMenu";

const NavMain = () => {
  let screenSize = useScreenSize();
  return (
    <header>
      {screenSize.width <= 768 ? (
        <HamburgerMenu />
      ) : (
        <nav className="navMain">
          <a className="logo" href="/">
            <Logo />
          </a>

          <ul className="list">
            <NavLink to="trainingen">
              {({ isActive, isPending, isTransitioning }) => (
                <div className="position">
                  <p>Trainingen</p>
                  <div
                    className={isActive ? "dot active" : "dot inActive"}
                  ></div>
                </div>
              )}
            </NavLink>

            <NavLink to="coaching">
              {({ isActive, isPending, isTransitioning }) => (
                <div className="position">
                  <p>Coaching</p>
                  <div
                    className={isActive ? "dot active" : "dot inActive"}
                  ></div>
                </div>
              )}
            </NavLink>

            <NavLink to="boeken">
              {({ isActive, isPending, isTransitioning }) => (
                <div className="position">
                  <p>Boeken</p>
                  <div
                    className={isActive ? "dot active" : "dot inActive"}
                  ></div>
                </div>
              )}
            </NavLink>

            <NavLink to="lezingen">
              {({ isActive, isPending, isTransitioning }) => (
                <div className="position">
                  <p>Lezingen en shows</p>
                  <div
                    className={isActive ? "dot active" : "dot inActive"}
                  ></div>
                </div>
              )}
            </NavLink>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default NavMain;
