import { useLoaderData } from "react-router-dom";
import StoryblokClient from "storyblok-js-client";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "./book.scss";

const Deepdive = (blok: any) => {
  const data: any = useLoaderData();
  blok = data;

  const image = blok.body[0].image;
  const path = image ? image.filename : "";
  const alt = image ? image.alt : "";
  const fullscreen = blok.body[0].fullscreen;
  const bgColorLeft = blok.body[0].bgLeftSide;

  return (
    <main {...storyblokEditable(blok)} className="page-book">
      <div className={`left ${bgColorLeft === "orange" ? "orange" : "pink"} `}>
        {path ? (
          <LazyLoadImage
            className={`${fullscreen ? "fullscreen-image" : "image"}`}
            src={path}
            alt={alt}
            effect="opacity"
            width={100 + "%"}
            height={100 + "%"}
            wrapperClassName="wrapper"
          />
        ) : (
          ""
        )}
      </div>
      <div className="right">
        {blok.body
          ? blok.body.map((blok: any) => (
              <StoryblokComponent blok={blok} key={blok._uid} />
            ))
          : null}
      </div>
    </main>
  );
};
export default Deepdive;

const Storyblok = new StoryblokClient({
  accessToken: process.env.REACT_APP_STORYBLOK_KEY,
});
export async function loader({ params }: any) {
  const id = params.itemId;

  try {
    const response = await Storyblok.get(`cdn/stories/${id}`, {
      version: "draft",
    });
    return response.data.story.content;
  } catch (error) {
    throw error;
  }
}
