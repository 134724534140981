import { storyblokEditable } from "@storyblok/react";
import "./stats.scss";
import CircleLong from "../SVG/CircleLong";
import UnderlineSmall from "../SVG/UnderlineSmall";
import type {
  StatisticsStoryblok,
  StatsListItemStoryblok,
  StatsMarkedTextStoryblok,
} from "../../../component-types-sb";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Stats = (blok: StatisticsStoryblok) => {
  blok = blok.blok;
  return (
    <section className="stats" {...storyblokEditable(blok)}>
      <div className="tag-container">
        {blok.titleSupport && <p className="sub-tag">{blok.titleSupport}</p>}

        <p className="tag">{blok.title}</p>
      </div>

      <div className="stats-container">
        {blok.list.map((blok: StatsListItemStoryblok) => (
          <div key={blok._uid}>
            <div className="stat">
              <p className="number">{blok.number}</p>
              <div className="img-container">
                <LazyLoadImage
                  effect="blur"
                  src={blok.image.filename}
                  alt="Faces"
                />
              </div>
            </div>
            {blok.text &&
              blok.text.map((blok: StatsMarkedTextStoryblok) => (
                <div key={blok._uid} className="stat-text">
                  <span className="relative">
                    {blok.markedText} &nbsp;
                    {blok.option === "circle" ? (
                      <div className="circle">
                        <CircleLong />
                      </div>
                    ) : blok.option === "underline" ? (
                      <div className="underline">
                        <UnderlineSmall />
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                  {blok.text}
                </div>
              ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Stats;
