import React from "react";
import "./testemonies.scss";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react"; // import from 'keen-slider/react.es' for to get an ES module
import type {
  SliderItemStoryblok,
  TestimonialsStoryblok,
} from "../../../component-types-sb";
import { storyblokEditable } from "@storyblok/react";
import MarkedText from "../../elements/MarketText";
import Arrow from "../SVG/ArrowSvg";

const Testemonies = (blok: TestimonialsStoryblok) => {
  blok = blok.blok;

  const clickHandlerLeft = () => {
    console.log("clicked");
    instanceRef.current?.prev();
    return;
  };
  function clickHandlerRight() {
    console.log("im working");
    instanceRef.current?.next();
    return;
  }
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slideChanged() {
        console.log("slide changed");
      },
      breakpoints: {
        "(min-width: 768px)": {
          slides: {
            perView: 1.2,
            spacing: 15,
          },
        },
      },

      loop: true,
    },
    [
      (slider) => {
        let timeout: any;
        let mouseOver = false;

        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );
  return (
    <section className="section-testemonies" {...storyblokEditable(blok)}>
      <div className="text-container">
        {blok.supTitle && <p className="subtitle">{blok.supTitle}</p>}
        {blok.title &&
          blok.title.map((blok: any) => (
            <MarkedText
              startText={blok.beforeText}
              circleText={blok.circleText}
              middelText={blok.middelText}
              underlinedText={blok.underline}
              endText={blok.afterText}
              handwritten={blok.handwritten}
              isSmall
              center={false}
              key={blok._uid}
              className="title"
            />
          ))}
        <div ref={sliderRef} className="keen-slider">
          {blok.testimonials &&
            blok.testimonials.map((blok: SliderItemStoryblok) => (
              <div
                key={blok._uid}
                className={`keen-slider__slide card ${
                  blok.backgroundColor === "purple"
                    ? "purple"
                    : blok.backgroundColor === "pink"
                    ? "pink"
                    : blok.backgroundColor === "green"
                    ? "green"
                    : ""
                }`}
              >
                <p className="card-place">{blok.Title}</p>
                <p className="card-text"> {blok.text}</p>
                <li className="card-name">{blok.name}</li>
              </div>
            ))}
        </div>
        <div className="button-container">
          <button
            className="btn-left"
            rel="noreffer"
            onClick={() => clickHandlerLeft()}
          >
            <Arrow className="btn" />
          </button>

          <button
            className="btn-right"
            rel="noreffer"
            onClick={() => clickHandlerRight()}
          >
            <Arrow className="btn" />
          </button>
        </div>
      </div>
    </section>
  );
};
export default Testemonies;
