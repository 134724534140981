import React from "react";
import { storyblokInit, apiPlugin } from "@storyblok/react";
import ReactDOM from "react-dom/client";
import "./styles/main.scss";
import App from "./App";
import HeroMain from "./components/HeroMain";
import TextBlok from "./components/TextBlok";
import Services from "./components/Services";
import Stats from "./components/Stats";
import NewsLetter from "./components/Newslettter";
import HeroDeep from "./components/HeroDeep";
import TextblokDeepdive from "./components/TextblokDeepdive";
import FullscreenImg from "./components/FullscreenImg";
import Pricing from "./components/Pricing";
import Testemonies from "./components/Testemonies";
import BreakText from "./elements/Alinia";

storyblokInit({
  accessToken: "RkDdParCLGtPpeiiRYBIYQtt",
  use: [apiPlugin],
  components: {
    heroMain: HeroMain,
    textBlok: TextBlok,
    services: Services,
    statistics: Stats,
    newspaper: NewsLetter,
    heroDeepdive: HeroDeep,
    textblokDeepdive: TextblokDeepdive,
    fullscreenImage: FullscreenImg,
    pricing: Pricing,
    testimonials: Testemonies,
    testText: BreakText,
  },
  apiOptions: {
    region: "eu",
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
