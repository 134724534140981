import { Link } from "react-router-dom";
import "./footer.scss";
import Logo from "../SVG/LogoSvg";
import Arrow from "../SVG/ArrowSvg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container-first">
        <Logo className="logo" />
        <div>
          <button
            className="button"
            rel="noreffer"
            onClick={() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <Arrow className="arrow" />
          </button>
        </div>
      </div>
      <div className="container-third">
        <div className="social-container">
          <a
            target="_blank"
            href="https://www.instagram.com/sannevanarnhem/"
            rel="noreferrer"
          >
            instagram
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/sannevanarnhem/"
            rel="noreferrer"
          >
            linkedin
          </a>
        </div>
        <div className="contact-container">
          <a target="_blank" href="tel:0031645810392" rel="noreferrer">
            0031645810392
          </a>
          <a
            target="_blank"
            href="mailto:sanne@sannevanarnhem.nl"
            rel="noreferrer"
          >
            sanne@sannevanarnhem.nl
          </a>
        </div>
      </div>
      <div className="container-fourth">
        <p className="rights">©2024 All Rights reserved</p>
      </div>
    </div>
  );
};
export default Footer;
