type ArrowProps = {
  className?: string;
};
const Arrow = ({ className }: ArrowProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <circle cx="32" cy="32" r="32" fill="#4CA747" />
      <path
        d="M38.7071 32.7071C39.0976 32.3166 39.0976 31.6834 38.7071 31.2929L32.3431 24.9289C31.9526 24.5384 31.3195 24.5384 30.9289 24.9289C30.5384 25.3195 30.5384 25.9526 30.9289 26.3431L36.5858 32L30.9289 37.6569C30.5384 38.0474 30.5384 38.6805 30.9289 39.0711C31.3195 39.4616 31.9526 39.4616 32.3431 39.0711L38.7071 32.7071ZM27 33H38V31H27V33Z"
        fill="black"
      />
    </svg>
  );
};
export default Arrow;
