import { storyblokEditable } from "@storyblok/react";
import "./pricing.scss";
import MarkedText from "../../elements/MarketText";
import Alinia from "../../elements/Alinia";
import type {
  PricingStoryblok,
  MarkedTextStoryblok,
  AliniaStoryblok,
  ButtonStoryblok,
} from "../../../component-types-sb";
import Button from "../../elements/Button";
const Pricing = (blok: PricingStoryblok) => {
  blok = blok.blok;
  const bg = blok.backgroundColor;

  return (
    <section
      className={`section-pricing ${bg === "purple" ? "purple" : "green"}`}
      {...storyblokEditable(blok)}
    >
      <div className={`text-container ${bg === "purple" ? "white" : "black"}`}>
        {blok.title &&
          blok.title.map((blok: MarkedTextStoryblok) => (
            <MarkedText
              startText={blok.beforeText}
              circleText={blok.circleText}
              middelText={blok.middelText}
              underlinedText={blok.underline}
              endText={blok.afterText}
              handwritten={blok.handwritten}
              isSmall
              center={false}
              key={blok._uid}
              className={`title`}
            />
          ))}
        {blok.price && (
          <p className="price" key={blok._uid}>
            <span>€</span> {blok.price}
          </p>
        )}
        {blok.description &&
          blok.description.map((blok: AliniaStoryblok) => (
            <Alinia
              className="alinia-spacing"
              key={blok._uid}
              text={blok.alinia}
            />
          ))}
        {blok.button &&
          blok.button.map((blok: ButtonStoryblok) => (
            <Button
              link={blok.Link.url}
              target={blok.Link.target}
              emailType={blok.Link.linktype}
              key={blok._uid}
              label={blok.text}
              className={` ${
                bg === "purple"
                  ? "button-pricing-white"
                  : "button-pricing-black"
              }`}
              icon="arrow-footer"
            />
          ))}
      </div>
    </section>
  );
};
export default Pricing;
