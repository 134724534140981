import React from "react";
import StoryblokClient from "storyblok-js-client";
import "../../styles/main.scss";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import { useLoaderData } from "react-router-dom";

const Home = (blok: any) => {
  const data: any = useLoaderData();
  blok = data.story.content;

  return (
    <main {...storyblokEditable(blok)} className="px-4">
      {blok.body
        ? blok.body.map((blok: any) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))
        : null}
    </main>
  );
};

export default Home;
const Storyblok = new StoryblokClient({
  accessToken: process.env.REACT_APP_STORYBLOK_KEY,
});
export async function loader() {
  let slug =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname.replace("/", "");

  try {
    const response = await Storyblok.get(`cdn/stories/${slug}`, {
      version: "draft",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
