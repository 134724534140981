const HeroVec2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="996"
      height="765"
      viewBox="0 0 996 765"
      fill="none"
    >
      <path
        d="M157.263 0L959.708 109.935L996 766H0L157.263 0Z"
        fill="#E90D7D"
      />
    </svg>
  );
};
export default HeroVec2;
