import { storyblokEditable } from "@storyblok/react";
import type { FullscreenImageStoryblok } from "../../../component-types-sb";
import "./fullscreenImg.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const FullscreenImg = (blok: FullscreenImageStoryblok) => {
  blok = blok.blok;
  return (
    <section className="section-fullscreen-img" {...storyblokEditable(blok)}>
      <LazyLoadImage
        src={blok.image.filename}
        alt={blok.image.alt}
        effect="blur"
        className="img"
        width={100 + "%"}
      />
    </section>
  );
};
export default FullscreenImg;
