import { storyblokEditable } from "@storyblok/react";
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
import Arrow from "../SVG/ArrowSvg";
import "./newsletter.scss";
import MarkedText from "../../elements/MarketText";
import Alinia from "../../elements/Alinia";
import type {
  NewspaperStoryblok,
  MarkedTextStoryblok,
  AliniaStoryblok,
} from "../../../component-types-sb";
const NewsLetter = (blok: NewspaperStoryblok) => {
  blok = blok.blok;
  const urlMailchimp =
    "https://sannevanarnhem.us11.list-manage.com/subscribe/post?u=0adcd5e8e775a3f6d94e6da23&amp;id=913f745f71&amp;f_id=00f596e0f0";
  const { loading, error, success, message, handleSubmit } =
    useMailChimpForm(urlMailchimp);
  const { fields, handleFieldChange } = useFormFields({
    EMAIL: "",
  });

  return (
    <section className="newsletter" {...storyblokEditable(blok)}>
      <div className="text-container">
        <p className="tag">{blok.tag}</p>
        {blok.title &&
          blok.title.map((blok: MarkedTextStoryblok) => (
            <MarkedText
              startText={blok.beforeText}
              circleText={blok.circleText}
              middelText={blok.middelText}
              underlinedText={blok.underline}
              endText={blok.afterText}
              key={blok._uid}
              isSmall
              center={false}
            />
          ))}

        {blok.description &&
          blok.description.map((blok: AliniaStoryblok) => (
            <Alinia key={blok._uid} className="sub-text" text={blok.alinia} />
          ))}
      </div>
      <form
        className="input-form-container"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(fields);
        }}
      >
        <div className="input-field-container">
          <input
            className="input-field"
            placeholder="Email"
            id="EMAIL"
            type="email"
            value={fields.EMAIL}
            onChange={handleFieldChange}
          />
          <button className="submit-button" type="submit">
            <Arrow />
          </button>
        </div>

        <div className="input-text-container">
          <p className="input-text">{blok.inputSupport}</p>
          <p className="input-text">
            {loading && "submitting"}
            {error && message}
            {success && message}
          </p>
        </div>
      </form>
    </section>
  );
};

export default NewsLetter;
