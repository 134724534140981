import { storyblokEditable } from "@storyblok/react";
import "./textblokDeepdive.scss";
import MarkedText from "../../elements/MarketText";
import Alinia from "../../elements/Alinia";
import type {
  TextblokDeepdiveStoryblok,
  MarkedTextStoryblok,
  AliniaStoryblok,
  BulletlistStoryblok,
} from "../../../component-types-sb";
const TextblokDeepdive = (blok: TextblokDeepdiveStoryblok) => {
  blok = blok.blok;

  return (
    <section {...storyblokEditable(blok)} className="textblok-deepdive">
      <div className="text-container">
        {blok.title &&
          blok.title.map((blok: MarkedTextStoryblok) => (
            <MarkedText
              startText={blok.beforeText}
              circleText={blok.circleText}
              middelText={blok.middelText}
              underlinedText={blok.underline}
              endText={blok.afterText}
              handwritten={blok.handwritten}
              isSmall
              center={false}
              key={blok._uid}
              className={`title`}
            />
          ))}
        {blok.subtitle &&
          blok.subtitle.map((blok: AliniaStoryblok) => (
            <Alinia key={blok._uid} className="subtitle" text={blok.alinia} />
          ))}

        {blok.text &&
          blok.text.map((blok: AliniaStoryblok) => (
            <Alinia
              key={blok._uid}
              className="alinia-spacing"
              text={blok.alinia}
            />
          ))}
        {blok.bulletList &&
          blok.bulletList.map((blok: BulletlistStoryblok) => (
            <div key={blok._uid} className="bulletlist-container">
              <Alinia
                key={blok._uid}
                className="alinia-spacing"
                text={blok.alinia}
              />
              <p className="alinia"> {blok.title}</p>
              <ul className="list">
                {blok.list &&
                  blok.list.map((blok: AliniaStoryblok) => (
                    <li key={blok._uid} className="alinia list-item">
                      {blok.alinia}
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </div>
    </section>
  );
};

export default TextblokDeepdive;
