import { Outlet, useParams, ScrollRestoration } from "react-router-dom";
import NavMain from "../components/NavMain";
import Footer from "../components/Footer";

const LayoutRoot = () => {
  const page = useParams();
  return (
    <main>
      <NavMain />
      <div>
        <ScrollRestoration
          getKey={(location, matches) => {
            return location.key;
          }}
        />
        <Outlet />
      </div>
      {page.itemId ? "" : <Footer />}
    </main>
  );
};

export default LayoutRoot;
