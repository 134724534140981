import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LayoutRoot from "./pages/Root";
import Home, { loader as homeLoader } from "./pages/Home";
import Trainingen, { loader as trainingenLoader } from "./pages/Trainingen";
import Writing, { loader as boekenLoader } from "./pages/Writing";
import Seminars, { loader as lezingenLoader } from "./pages/Seminars";
import Deepdive, { loader as diveLoader } from "./pages/Deepdive";
import type { PageStoryblok } from "../component-types-sb";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutRoot />,
    children: [
      {
        index: true,
        loader: homeLoader,
        element: <Home />,
      },
      {
        path: "trainingen",
        loader: trainingenLoader,
        element: <Trainingen />,
      },
      {
        path: ":itemId",
        loader: diveLoader,
        element: <Deepdive />,
      },
      {
        path: "boeken",
        loader: boekenLoader,
        element: <Writing />,
      },
      {
        path: "lezingen",
        loader: lezingenLoader,
        element: <Seminars />,
      },
      { path: "boeken/:itemId", loader: diveLoader, element: <Deepdive /> },
      {
        path: "trainingen/:itemId",
        loader: diveLoader,
        element: <Deepdive />,
      },
      { path: "lezingen/:itemId", loader: diveLoader, element: <Deepdive /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
