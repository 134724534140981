const Underline = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="600"
      height="28"
      viewBox="0 0 600 28"
      fill="none"
    >
      <path
        d="M457.631 2.28258C329.48 2.28258 197.421 1.78835 70.5256 4.64973C49.5136 5.12354 26.4287 6.27715 5.32457 6.27715C-20.5098 6.27715 56.9933 6.27715 82.8277 6.27715C227.568 6.27715 373.12 5.36546 517.501 7.31278C542.237 7.6464 609.263 5.11044 591.724 8.27444C576.532 11.015 515.758 10.242 496.998 10.2717C384.865 10.4492 270.58 8.95683 159.101 11.3073C130.494 11.9105 100.577 12.9348 71.7558 12.9348C47.1042 12.9348 120.596 14.1835 145.158 14.5622C301.227 16.9685 446.676 18.1684 597.875 26.25"
        stroke="#E90D7D"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Underline;
