type HBProps = {
  className?: string;
  onClick?: () => void;
};

const HamburgerSvg = ({ className, onClick }: HBProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
      onClick={(e) => {
        e.preventDefault();
        // onClick();
      }}
    >
      <circle cx="24" cy="24" r="24" fill="#4CA747" />
      <path d="M31 21.6H17V20H31V21.6Z" fill="black" />
      <path d="M31 24.8H17V23.2H31V24.8Z" fill="black" />
      <path d="M17 28H31V26.4H17V28Z" fill="black" />
    </svg>
  );
};

export default HamburgerSvg;
