import { Link } from "react-router-dom";
import Arrow from "../../components/SVG/ArrowSvg";
import DownloadIcon from "../../components/SVG/Download";

import "./button.scss";

type ButtonProps = {
  label?: string;
  className?: string;
  onClick?: () => void;
  link?: string;
  icon: string;
  target?: string;
  emailType?: string;
};

const Button = ({
  label,
  className,
  link,

  icon,
  target,
  emailType,
}: ButtonProps) => {
  function getLink() {
    if (emailType === "email") {
      link = `mailto:${link}`;
    }
    return link;
  }
  getLink();
  return (
    <Link
      to={link ? link : ""}
      target={target ? target : "_none"}
      className={`button ${className}`}
      onClick={() => {}}
    >
      {icon === "arrow" ? (
        <Arrow className="icon-arrow" />
      ) : icon === "download" ? (
        <DownloadIcon className="icon-download" />
      ) : icon === "arrow-menu" ? (
        <Arrow className="icon-arrowMenu" />
      ) : icon === "arrow-footer" ? (
        <Arrow className="icon-arrowFooter" />
      ) : (
        ""
      )}

      {label && icon === "arrow-menu" ? (
        <p className="text menu">{label}</p>
      ) : (
        <p className="text">{label}</p>
      )}
    </Link>
  );
};

export default Button;
