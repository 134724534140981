const CircleLong = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="187"
      height="48"
      viewBox="0 0 187 48"
      fill="none"
    >
      <path
        d="M162.146 4.79845C115.341 0.3256 18.0714 -2.9 3.42785 19.9804C-14.8766 48.5809 112.886 50.7143 163.273 41.6602C193.531 36.2229 210.302 5.85036 89.4542 5.11634"
        stroke="#93B2F2"
        stroke-width="2"
      />
    </svg>
  );
};
export default CircleLong;
