const HamOpen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <circle cx="24" cy="24" r="24" fill="#252632" />
      <path
        d="M24.273 25.3337L27.9853 29.0459L29.0459 27.9853L25.3337 24.273L29.0461 20.5607L27.9854 19.5L24.273 23.2124L20.5607 19.5L19.5 20.5607L23.2124 24.273L19.5001 27.9853L20.5608 29.0459L24.273 25.3337Z"
        fill="#4CA747"
      />
    </svg>
  );
};

export default HamOpen;
