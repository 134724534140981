import { storyblokEditable } from "@storyblok/react";
import type {
  HeroMainStoryblok,
  MarkedTextStoryblok,
} from "../../../component-types-sb";
import "./heroMain.scss";
import HeroVect1 from "../SVG/HeroVec1";
import HeroVec2 from "../SVG/HeroVec2";
import Button from "../../elements/Button";
import MarkedText from "../../elements/MarketText";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const HeroMain = (blok: HeroMainStoryblok) => {
  blok = blok.blok;
  const imagePath = blok.image.filename;
  const imageName = blok.image.alt;

  return (
    <section className="heroMain" {...storyblokEditable(blok)}>
      <div className="left">
        <div className="text-container">
          {blok.title.map((blok: MarkedTextStoryblok) => (
            <MarkedText
              startText={blok.beforeText}
              circleText={blok.circleText}
              middelText={blok.middelText}
              underlinedText={blok.underline}
              endText={blok.afterText}
              handwritten={blok.handwritten}
              isSmall
              center={false}
              key={blok._uid}
            />
          ))}

          {blok.buyButton &&
            blok.buyButton.map((blok) => (
              <Button
                link={blok.Link.url}
                target={blok.Link.target}
                emailType={blok.Link.linktype}
                key={blok._uid}
                label={blok.text}
                className="buy-hero"
                icon="arrow"
              />
            ))}
          {blok.downloadItems &&
            blok.downloadItems.map((blok) => (
              <div key={blok._uid}>
                <p className="download-text">{blok.text}</p>
                <div className="download-container">
                  {blok.items &&
                    blok.items.map((blok) => (
                      <Button
                        link={blok.Link.url}
                        target={blok.Link.target}
                        key={blok._uid}
                        label={blok.text}
                        className="download-hero"
                        icon="download"
                      />
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="hero-main-right">
        <div className="absolute-container">
          <HeroVect1 />
        </div>
        <div className="absolute-container">
          <HeroVec2 />
        </div>
        <div className="img-container">
          <LazyLoadImage effect="blur" src={imagePath} alt={imageName} />
        </div>
      </div>
    </section>
  );
};

export default HeroMain;
