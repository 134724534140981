const UnderlineSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="12"
      viewBox="0 0 71 12"
      fill="none"
    >
      <path
        d="M17.4234 1.01358C32.4306 1.01358 47.8954 0.807646 62.7557 1.99989C65.2163 2.19731 67.9197 2.67798 70.3911 2.67798C73.4165 2.67798 64.3404 2.67798 61.315 2.67798C44.3651 2.67798 27.3202 2.29811 10.4122 3.10949C7.51555 3.2485 -0.333652 2.19185 1.72033 3.51018C3.49941 4.65207 10.6164 4.33001 12.8133 4.34238C25.9447 4.41632 39.3282 3.79451 52.383 4.7739C55.733 5.02521 59.2365 5.45199 62.6116 5.45199C65.4985 5.45199 56.8921 5.97228 54.0158 6.13008C35.7392 7.1327 18.7063 7.63269 1 11"
        stroke="#93B2F2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default UnderlineSmall;
