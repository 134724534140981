import { useLoaderData } from "react-router-dom";
import StoryblokClient from "storyblok-js-client";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react";

const Writing = (blok: any) => {
  const data: any = useLoaderData();
  blok = data.story.content;

  return (
    <main {...storyblokEditable(blok)} className="px-4">
      {blok.body
        ? blok.body.map((blok: any) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))
        : null}
    </main>
  );
};

export default Writing;

const Storyblok = new StoryblokClient({
  accessToken: process.env.REACT_APP_STORYBLOK_KEY,
});
export async function loader() {
  try {
    const response = await Storyblok.get("cdn/stories/boeken", {
      version: "draft",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
