import { storyblokEditable } from "@storyblok/react";
import "./service.scss";
import Button from "../../elements/Button";
import Alinia from "../../elements/Alinia";
import Arrow from "../SVG/ArrowSvg";

import { useState } from "react";
import useScreenSize from "../../screenSize";
import type {
  ServicesStoryblok,
  MenuItemStoryblok,
  ButtonStoryblok,
} from "../../../component-types-sb";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";

const Services = (blok: ServicesStoryblok) => {
  blok = blok.blok;

  const bg = blok.backgroundColor;
  const screenSize = useScreenSize();
  const [hoverdTitle, setHoverdTitle] = useState<number | null>(0);

  const handleHovered = (index: number | null) => {
    if (index === null || index === undefined) {
      return;
    }
    return setHoverdTitle(index);
  };

  return (
    <section
      className={`services ${bg === "green" ? "green" : "dark-blue"}`}
      {...storyblokEditable(blok)}
    >
      {blok.tag ? (
        <p className={`tag ${bg === "green" ? "green" : "dark-blue"}`}>
          {blok.tag}
        </p>
      ) : (
        ""
      )}

      <div className={`img-container ${blok.tag ? "hero" : ""}`}>
        {blok.menu && (
          <LazyLoadImage
            effect="blur"
            src={blok.menu[hoverdTitle].image.filename}
            alt="Workshop"
            width={100 + "%"}
            height={100 + "%"}
          />
        )}
      </div>
      {screenSize.width <= 768 ? (
        <div className="text-container">
          <ul className={`list ${blok.tag ? "test" : ""}`}>
            {blok.menu.map((blok: MenuItemStoryblok, index: number) => (
              <li className="list-item" key={index}>
                {blok.button &&
                  blok.button.map((blok: ButtonStoryblok) => (
                    <Link
                      className={`service-button ${
                        bg === "green" ? "black" : "white"
                      } ${hoverdTitle === index ? "active" : ""}  `}
                      to={blok.Link.cached_url}
                      key={blok._uid}
                    >
                      <Arrow className="arrow" />
                      <p>{blok.text}</p>
                    </Link>
                  ))}
                {blok.description[0] ? (
                  <Alinia text={blok.description[0].alinia} />
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="text-container">
          <ul className={`list ${blok.tag ? "no-padding" : ""}`}>
            {blok.menu.map((blok: MenuItemStoryblok, index: number) => (
              <li
                className="list-item"
                key={blok._uid}
                onMouseEnter={() => handleHovered(index)}
              >
                {blok.button &&
                  blok.button.map((blok: ButtonStoryblok) => (
                    <Link
                      className={`service-button ${
                        bg === "green" ? "black" : "white"
                      } ${hoverdTitle === index ? "active" : ""}  `}
                      to={blok.Link.cached_url}
                      key={blok._uid}
                    >
                      <Arrow className="arrow" />
                      <p>{blok.text}</p>
                    </Link>
                  ))}
              </li>
            ))}
          </ul>
          {screenSize.width <= 768 ? (
            ""
          ) : (
            <>
              {blok.menu[hoverdTitle].description[0] ? (
                <Alinia
                  text={blok.menu[hoverdTitle].description[0].alinia}
                  className="sub-text"
                />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default Services;
